body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Urbanist-Medium" "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: grey;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Urbanist-Medium", "Courier New", monospace;
}

/* Medium = 500 */
@font-face {
  font-family: "Urbanist-Medium";
  src: local("Urbanist-Medium"), url("./assets/fonts/Urbanist-Medium.ttf") format("truetype");
  font-weight: Medium;
}

/* Normal(Regular) = 400 */
@font-face {
  font-family: "Urbanist-Regular";
  src: local("Urbanist-Regular"), url("./assets/fonts/Urbanist-Regular.ttf") format("truetype");
  font-weight: Normal;
}

/* Bold 700 */
@font-face {
  font-family: "Urbanist-Bold";
  src: local("Urbanist-Bold"), url("./assets/fonts/Urbanist-Bold.ttf") format("truetype");
  font-weight: Bold;
}

@font-face {
  font-family: "Urbanist-ExtraBold";
  src: local("Urbanist-ExtraBold"), url("./assets/fonts/Urbanist-ExtraBold.ttf") format("truetype");
  font-weight: ExtraBold;
}
